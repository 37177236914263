import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./slices/login.slice";
import registerStaffSlice from "./slices/registerStaff.slice";
import dashboardSlice from "./slices/DashboardSlice/dashboard.slice";
import bankDetailsSlice from "./slices/bankDetails.slice";

import departmentSlice from "./slices/department.slice";
import attendanceSlice from "./slices/attendance.slice";
import error from "./slices/error";
import monthlyAttendanceSlice from "./slices/monthlyAttendance.slice";
import userdetailsSlice from "./slices/usernameInNav.slice";
import applyLeaveSlice from "./slices/applyLeave.slice";
import allLeaveSlice from "./slices/allLeave.slice";
// my
import companySlice from "./slices/company.slice";
import ExpenseDetailsSlice from "./slices/expenses.slice";
import getleaveSlice from "./slices/getleave.slice";
import allRolesSlice from "./slices/roles.slice";
import payrollSlice from './slices/payroll.slice';
import assetSlice from "./slices/assets.slice";
import assignAssetSlice from "./slices/assignAsset.slice";
import taskSlice from "./slices/task.slice"
import stateSlice from "./slices/state.slice";

import technologySlice from "./slices/technology.slice";

// AssetType 
import assetTypeSlice from "./slices/assetType.slice"; 
import holidaySlice from "./slices/holiday.slice";
import previousAttendances from "./slices/previousAttendance.slice"

// Customer
import customerSlice from "./slices/customer.slice";

//Resume---
import resumeManagementSlice from "./slices/resumeManagement.slice";
import invoiceSlice from "./slices/invoice.slice";
import serviceSlice from "./slices/service.slice";
import permissionSlice from "./slices/permission.slice";
import menuPermissionSlice from "./slices/menuPermission.slice";
import userInfoSlice from "./slices/userInfo.slice";

import branchSlice from "./slices/branch.slice";

//LeadManagement--------------
import leadManagementSlice from "./slices/leadManagement.slice";

import leaveBalanceSlice from "./slices/leaveBalance.slice";
// -------------//----------------------

export const store = configureStore({
  reducer: {
    login: loginSlice,
    registerStaff: registerStaffSlice,
    dashboard: dashboardSlice,
    attendance: attendanceSlice,
    bankDetails: bankDetailsSlice,
    error: error,
    monthlyAttendance: monthlyAttendanceSlice,
    userdetails: userdetailsSlice,
    applyleave: applyLeaveSlice,
    allLeaveSlice: allLeaveSlice,
    department: departmentSlice,
    getleave: getleaveSlice,
    company: companySlice,
    expenseDetails: ExpenseDetailsSlice,
    rolesSlice: allRolesSlice,
    assetType : assetTypeSlice,
    payroll:payrollSlice,
    addAssetSlice: assetSlice,
    assignAsset:assignAssetSlice,
    asset: assetSlice,
    task: taskSlice,
    holidays:holidaySlice,
    previousAttendance:previousAttendances,
    customer : customerSlice,
    invoiceSlice:invoiceSlice,
    serviceSlice:serviceSlice,
    state:stateSlice,
    technology : technologySlice,
    resume : resumeManagementSlice,
    invoice:invoiceSlice,
    service:serviceSlice,
    permission:permissionSlice,
    menuPermission:menuPermissionSlice,
    userInfo:userInfoSlice,
    branch: branchSlice,
    leadManagement: leadManagementSlice,
    leaveBalance : leaveBalanceSlice,
  },
});
