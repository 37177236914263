import React from "react";
import { Button, Stack, TextField, FormControl, Box } from "@mui/material";
import * as Yup from "yup";

import {
  addAssetTypeAsync,
  getAllAssetTypeAsync,
  putAssetTypeAsync,
  getAssetTypeByIdAsync,
} from "src/redux/async.api";

import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useFormik } from "formik";
import { emptyAssetType } from "src/redux/slices/assetType.slice";
import { toast } from "react-hot-toast";
import { toastoptions } from "src/utils/toastoptions";

const AddNewAssetType = ({ setopenPopup, AssetTypeId }) => {
  const dispatch = useDispatch();

  const { addAssetType, assetTypeLoader, putAssetType, getAssetTypeById } =
    useSelector((state) => state.assetType);

  const initialValues = {
    assetTypeName: "",
  };

  const validationSchema = Yup.object().shape({
    assetTypeName: Yup.string().required("AssetType is Required"),
  });

  useEffect(() => {
    if (getAssetTypeById && AssetTypeId) {
      formik.setFieldValue(
        "assetTypeName",
        getAssetTypeById?.data?.assetTypeName
      );
    }
  }, [getAssetTypeById, AssetTypeId]);

  const getIdforAssetType = () => {
    const payload = {
      id: AssetTypeId,
    };
    dispatch(getAssetTypeByIdAsync(payload));
  };

  useEffect(() => {
    if (AssetTypeId) {
      getIdforAssetType(AssetTypeId);
    } 
    
  }, [AssetTypeId]);

  const onSubmit = async (value) => {

    const trimmedValues = {
      ...value,
      assetTypeName: value.assetTypeName.trim(),
    };

    const payload = {
      id: AssetTypeId,
      body: { assetTypeName: trimmedValues.assetTypeName },
    };

    if (AssetTypeId) {
      dispatch(putAssetTypeAsync(payload)).then(() => {
        dispatch(getAllAssetTypeAsync())
      });

    } else {
      dispatch(
        addAssetTypeAsync(trimmedValues)
      ).then(() => {
        // dispatch(getAllAssetTypeAsync());  // for Future Requirement
        dispatch(emptyAssetType());
        assetTypeLoader(true);
      }).catch(()=>{
        dispatch(getAllAssetTypeAsync());
        assetTypeLoader(true);
      })
    }
  };

 

  useEffect(() => {
    if (addAssetType?.status === true && !AssetTypeId) {
      dispatch(getAllAssetTypeAsync());
      toast.success(addAssetType?.message, toastoptions);
      setopenPopup(false);
      dispatch(emptyAssetType());
    } else if ( putAssetType?.status === true && AssetTypeId ) {
      toast.success( putAssetType?.message, toastoptions )
      setopenPopup(false);
      dispatch(emptyAssetType());
    }
  }, [ addAssetType , putAssetType , setopenPopup ]);


  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  return (
    <div>
      <form component="form" onSubmit={formik.handleSubmit}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <Box>
            <TextField
              fullWidth
              name="assetTypeName"
              label="Type"
              {...formik.getFieldProps("assetTypeName")}
              onChange={(e) => {
                formik.setFieldValue("assetTypeName", e.target.value);
                formik.handleChange(e);
              }}
              error={
                formik.touched.assetTypeName && formik.errors.assetTypeName
              }
              helperText={
                formik.touched.assetTypeName && formik.errors.assetTypeName
              }
            />

            <Stack alignItems="flex-end" sx={{ mt: 3, mb: 4 }}>
              <Button
                type="submit"
                variant="contained"
                loading={assetTypeLoader}
              >
                {!AssetTypeId ? "Add" : "Update"}
              </Button>
            </Stack>
          </Box>
        </FormControl>
      </form>
    </div>
  );
};

export default AddNewAssetType;
