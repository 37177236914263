import { createSlice , isAnyOf } from "@reduxjs/toolkit";
import {getalleaveAsync,putleaveAsync} from '../async.api';

const initialState = {
    allLeaveLoader :false,
    allLeaveData: [] ,
    allLeaveError:[],
    putleaveRequest:[]
}

export const allLeaveSlice = createSlice({
    name:"allLeaveSlice",
    initialState,
    extraReducers:(builder)=>{
        builder.addMatcher(isAnyOf(getalleaveAsync.pending ,putleaveAsync.pending),(state)=>{
            state.allLeaveLoader= true;
        });
        builder.addMatcher(isAnyOf(getalleaveAsync.fulfilled), (state, action) => {
            state.allLeaveLoader = false;
            state.allLeaveData = action.payload;
          });
          builder.addMatcher(isAnyOf(putleaveAsync.fulfilled), (state, action) => {
            state.allLeaveLoader = false;
            state.putleaveRequest = action.payload;
          });
          builder.addMatcher(isAnyOf(getalleaveAsync.rejected,putleaveAsync.rejected), (state, action) => {
            state.allLeaveLoader = false;
            state.allLeaveError = action.payload;
          });
          
    },
    reducers: {
        emptyALlLeave: (state) => {
          return {
            ...initialState,
          };
        },
      },
})

 export const { emptyALlLeave } = allLeaveSlice.actions;

export default allLeaveSlice.reducer;