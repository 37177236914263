import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllLeadsListAsync,
  getLeadByIdAsync,
  addNewLeadAsync,
  updateLeadAsync,
  deleteLeadAsync,
  postFollowUpAsync,
  deleteFollowUpAsync,
  updateFollowUpAsync
} from "../async.api";

const initialState = {
  leadsLoader: false,
  getAllLeadsList: [],
  getLeadById: [],
  addNewLead: [],
  updateLead: [],
  deleteLead: [],
  addFollowUp:[],
  deleteFollowUp:[],
  updateFollowUp:[],
};

const leadManagementSlice = createSlice({
  name: "leadManagement",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllLeadsListAsync.pending,
        getLeadByIdAsync.pending,
        addNewLeadAsync.pending,
        updateLeadAsync.pending,
        deleteLeadAsync.pending,
        postFollowUpAsync.pending,
        deleteFollowUpAsync.pending,
        updateFollowUpAsync.pending
      ),
      (state) => {
        state.leadsLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllLeadsListAsync.fulfilled),
      (state, action) => {
        state.leadsLoader = false;
        state.getAllLeadsList = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(getLeadByIdAsync.fulfilled), (state, action) => {
      state.leadsLoader = false;
      state.getLeadById = action.payload;
    });
    builder.addMatcher(isAnyOf(addNewLeadAsync.fulfilled), (state, action) => {
      state.leadsLoader = false;
      state.addNewLead = action.payload;
    });
    builder.addMatcher(isAnyOf(updateLeadAsync.fulfilled), (state, action) => {
      state.leadsLoader = false;
      state.updateLead = action.payload;
    });
    builder.addMatcher(
      isAnyOf(deleteLeadAsync.fulfilled),
      (state, action) => {
        state.leadsLoader = false;
        state.deleteLead = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(postFollowUpAsync.fulfilled), (state, action) => {
      state.leadsLoader = false;
      state.addFollowUp = action.payload;
    });
    builder.addMatcher(
      isAnyOf(deleteFollowUpAsync.fulfilled),
      (state, action) => {
        state.leadsLoader = false;
        state.deleteFollowUp = action.payload;
      }
    );
    builder.addMatcher(isAnyOf(updateFollowUpAsync.fulfilled), (state, action) => {
      state.leadsLoader = false;
      state.updateFollowUp = action.payload;
    });
    builder.addMatcher(
        isAnyOf(
            getAllLeadsListAsync.rejected,
            getLeadByIdAsync.rejected,
            addNewLeadAsync.rejected,
            updateLeadAsync.rejected,
            deleteLeadAsync.rejected,
            postFollowUpAsync.rejected,
            deleteFollowUpAsync.rejected,
            updateFollowUpAsync.rejected
        ),
        (state) => {
          state.leadsLoader = true;
        }
      );
  },

  reducers: {
    emptyLeads: () => initialState,
  },
  
});


export const { emptyLeads } = leadManagementSlice.actions;

export default leadManagementSlice.reducer;