import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getExpensesAsync,
  addExpensesAsync,
  putExpensesAsync,
  deleteExpensesAsync,
  getExpenseByIdAsync,
  putMultipleStatusAsync
} from "../async.api";

const initialState = {
  addingExpenseInfoLoader: false,
  addExpenseData: [],
  addExpenseInfoError: [],
  putExpenseData: [],
  getExpenseData: [],
  deleteExpense: [],
  getExpenseDataById: [],
  putMultipleStatusData:[]
};

export const ExpenseDetailsSlice = createSlice({
  name: "expenseDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addExpensesAsync.pending,
        getExpensesAsync.pending,
        putExpensesAsync.pending,
        getExpenseByIdAsync.pending,
        putMultipleStatusAsync.pending
      ),
      (state) => {
        state.addingExpenseInfoLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(addExpensesAsync.fulfilled), (state, action) => {
      state.addingExpenseInfoLoader = false;
      state.addExpenseData = action.payload;
    });

    builder.addMatcher(isAnyOf(getExpensesAsync.fulfilled), (state, action) => {
      state.addingExpenseInfoLoader = false;
      state.getExpenseData = action.payload;
    });

    builder.addMatcher(isAnyOf(putExpensesAsync.fulfilled), (state, action) => {
      state.addingExpenseInfoLoader = false;
      state.putExpenseData = action.payload;
    });
    
    builder.addMatcher(isAnyOf(putMultipleStatusAsync.fulfilled), (state, action) => {
      state.addingExpenseInfoLoader = false;
      state.putMultipleStatusData = action.payload;
    });

    builder.addMatcher(
      isAnyOf(getExpenseByIdAsync.fulfilled),
      (state, action) => {
        state.addingExpenseInfoLoader = false;
        state.getExpenseDataById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteExpensesAsync.fulfilled),
      (state, action) => {
        state.addingExpenseInfoLoader = false;
        state.deleteExpense = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        putExpensesAsync.rejected,
        addExpensesAsync.rejected,
        getExpensesAsync.rejected,
        getExpenseByIdAsync.rejected,
        putMultipleStatusAsync.rejected
      ),
      (state, action) => {
        state.addingExpenseInfoLoader = false;
      }
    );
  },
  reducers: {
    emptyExpenseInfo: () => initialState,
  },
});

export const { emptyExpenseInfo } = ExpenseDetailsSlice.actions;

export default ExpenseDetailsSlice.reducer;
