import SvgColor from '../../../components/svg-color/SvgColor';
import { PATH_DASHBOARD } from '../../../routes/paths';

export const PATH_ROUTE = {
app: PATH_DASHBOARD.general.app,
attendance: PATH_DASHBOARD.attendance.list,
monthly: PATH_DASHBOARD.general.monthly,
staff: PATH_DASHBOARD.staff.list,
Master:PATH_DASHBOARD.master.root,
department: PATH_DASHBOARD.master.department,
company: PATH_DASHBOARD.master.company ,
branch:PATH_DASHBOARD.master.branch,
roles:PATH_DASHBOARD.master.roles,
states:PATH_DASHBOARD.master.states,
technology:PATH_DASHBOARD.master.technology,
services:PATH_DASHBOARD.master.services,
expense:PATH_DASHBOARD.expense.table,
"General Settings":PATH_DASHBOARD.GeneralSetting.root,
siteSetting:PATH_DASHBOARD.GeneralSetting.siteSetting,
createpreviousattendance:PATH_DASHBOARD.GeneralSetting.createpreviousattendance,
holiday: PATH_DASHBOARD.GeneralSetting.holiday,
"Leave Management":PATH_DASHBOARD.leavemanagment.root,
table:PATH_DASHBOARD.leavemanagment.table,
new:PATH_DASHBOARD.leavemanagment.new,
list :PATH_DASHBOARD.leavemanagment.list,
payroll :PATH_DASHBOARD.payroll.table,
"Inventory Management":PATH_DASHBOARD.InventoryManagement.root,
assettype:PATH_DASHBOARD.InventoryManagement.assettype,
addAsset:PATH_DASHBOARD.InventoryManagement.addAsset,
assignAsset:PATH_DASHBOARD.InventoryManagement.assignAsset,
customer:PATH_DASHBOARD.customer.list,
"Task Management":PATH_DASHBOARD.TaskManagement.root,
addTask:PATH_DASHBOARD.TaskManagement.addTask ,
"Invoice Management":PATH_DASHBOARD.invoice.root,
invoicelist:PATH_DASHBOARD.invoice.list,
resumemanagement:PATH_DASHBOARD.resumemanagement.list,
leadmanagement:PATH_DASHBOARD.leadmanagement.list
};

export const IconsGenerate = (name) => (
<SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);