import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { storeModulePermit } from '../../redux/slices/menuPermission.slice';

// eslint-disable-next-line react/prop-types
const RoutePermissionMiddleware = ({ children }) => {
let { pathname } = useLocation();
const dispatch = useDispatch();
const { routePermission } = useSelector((state) => state.menuPermission);

useEffect(() => {
// ROUTE CHECKING
if (routePermission.length > 0) {
if (_.includes(pathname, 'edit')) {
// eslint-disable-next-line react-hooks/exhaustive-deps
pathname = `${pathname.split('edit/')[0]}edit`;
}
const findRoute = _.find(routePermission, (ev) => ev.path === pathname);
dispatch(storeModulePermit(findRoute));
}
}, [routePermission]);

return <Box>{children}</Box>;
};

export default RoutePermissionMiddleware;