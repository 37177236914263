// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Label from "../../../components/label";
import Iconify from "../../../components/iconify";
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  // blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  // chat: icon("ic_chat"),
  // mail: icon("ic_mail"),
  user: icon("ic_user"),
  inventory: icon("inventory-2"),
  tasks: icon("task-fill"),
  invoices: icon("invoice-solid"),

  // file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  // blank: icon("ic_blank"),
  // kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  attendance: icon("ic_attendance"),
  master: icon("ic_master"),
  setting: icon("ic_setting"),

  payroll: icon("ic_payroll"),
  leavemanagment: icon("leave_management"),
  resume: icon("ic_resume"),
  leadmanagement: icon("ic_leads"),
  
  // invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  // disabled: icon("ic_disabled"),
  // external: icon("ic_external"),
  // menuItem: icon("ic_menu_item"),
  // ecommerce: icon("ic_ecommerce"),
  // analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
  invoice: icon('ic_invoice'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      {
        title: "Attendance",
        path: PATH_DASHBOARD.attendance,
        icon: ICONS.attendance,
      },
      {
        title: "Monthly Attendance",
        path: PATH_DASHBOARD.general.monthly,
        icon: ICONS.booking,
      },

      {
        title: "Staff",
        path:PATH_DASHBOARD.staff.list,
        icon: ICONS.user,
      },    
     {
        title: " Master",
         path: PATH_DASHBOARD.master.root,
        icon: ICONS.master,
        children: [
          { title: "Department", path: PATH_DASHBOARD.master.department},
          { title: "Company", path: PATH_DASHBOARD.master.company },
          {title:"Branch",path:PATH_DASHBOARD.master.branch},
          { title: "Roles", path: PATH_DASHBOARD.master.roles },
          { title: "States",path:PATH_DASHBOARD.master.states },
          { title: "Technology", path: PATH_DASHBOARD.master.technology},
          { title: "Services", path: PATH_DASHBOARD.master.services},
        ],
      },
        {
        title: "Expense",
        path: PATH_DASHBOARD.expense.root,
        icon: ICONS.banking,
      },


      {
        title: "General Settings",
        path: PATH_DASHBOARD.GeneralSetting.root,
        icon: ICONS.setting,
        children: [
          { title: "Site Settings", path: PATH_DASHBOARD.GeneralSetting.siteSetting },
          { title: "Holidays", path: PATH_DASHBOARD.GeneralSetting.holiday },
          { title: "Create Previous Attendance", path: PATH_DASHBOARD.GeneralSetting.createpreviousattendance }
        ],
      },
      {
        title: "Leave Management",
        path: PATH_DASHBOARD.leavemanagment.root,
        icon: ICONS.leavemanagment,
        children: [
          { title: "My Leave", path: PATH_DASHBOARD.leavemanagment.table },
          { title: "Apply Leave", path: PATH_DASHBOARD.leavemanagment.new },
          { title: "All Leave", path: PATH_DASHBOARD.leavemanagment.list },
        ],
      },
      {
        title: "Payroll",
        path: PATH_DASHBOARD.payroll,
        icon: ICONS.payroll,
      },

      {
        title: "Inventory Management",
        path: PATH_DASHBOARD.InventoryManagement.root,
        icon: ICONS.inventory,
        children: [
          {
            title: "Asset Type",
            path: PATH_DASHBOARD.InventoryManagement.assettype,
          },
          {
            title: "Add Asset",
            path: PATH_DASHBOARD.InventoryManagement.addAsset,
          },
          {
            title: "Assign Asset",
            path: PATH_DASHBOARD.InventoryManagement.assignAsset,
          },
        ],
      },

      {
        title: "Customer",
        path:PATH_DASHBOARD.customer.list,
        icon: ICONS.user,
      },

      {
        title: "Task Management",
        path: PATH_DASHBOARD.TaskManagement.root,
        icon: ICONS.tasks,
        children: [
          { title: "Add Task", path: PATH_DASHBOARD.TaskManagement.addTask },
        ],
      },
     {
        title: 'Invoice Management',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        children: [
          { title: 'list', path: PATH_DASHBOARD.invoice.list },
          
        ],
      },

      {
        title: "Resume Management",
        path: PATH_DASHBOARD.resumemanagement.root,
        icon: ICONS.resume,
      },

      {
        title: "Lead Management",
        path:PATH_DASHBOARD.leadmanagement.list,
        icon: ICONS.leadmanagement,
      },







      // {
      //       title: "user",
      //       path: PATH_DASHBOARD.user.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: "profile", path: PATH_DASHBOARD.user.profile },
      //         // { title: "cards", path: PATH_DASHBOARD.user.cards },
      //         { title: "list", path: PATH_DASHBOARD.user.list },
      //         // { title: "create staff", path: PATH_DASHBOARD.user.new },
      //         // { title: "edit", path: PATH_DASHBOARD.user.demoEdit },
      //         // { title: "account", path: PATH_DASHBOARD.user.account },
      //       ],
      //     },

      // { title: "file", path: PATH_DASHBOARD.general.file, icon: ICONS.file },
    ],
  },
];

export default navConfig;
