import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getAttendAsync, putAttendanceStatusAsync,putCheckedDataAsync } from "../async.api";

const initialState = {
  attendLoder: false,
  attendData: [],
  attendError: [],
  attendStatus: [],
  editbtndata: [],
  attendCheckData: [],
};

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getAttendAsync.pending, putAttendanceStatusAsync.pending,putCheckedDataAsync.pending),
      (state) => {
        state.attendLoder = true;
      }
    );
    builder.addMatcher(isAnyOf(getAttendAsync.fulfilled), (state, action) => {
      state.attendLoder = false;
      state.attendData = action.payload;
    });

    builder.addMatcher(
      isAnyOf(putAttendanceStatusAsync.fulfilled),
      (state, action) => {
        state.attendLoder = false;
        state.editbtndata = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(putCheckedDataAsync.fulfilled),
      (state, action) => {
        state.attendLoder = false;
        state.attendCheckData = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAttendAsync.rejected, putAttendanceStatusAsync.rejected,putCheckedDataAsync.rejected),
      (state, action) => {
        state.attendLoder = false;
        state.attendError = action.payload;
      }
    );
  },
  reducers: {
    emptyAttendance: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyAttendance } = attendanceSlice.actions;

export default attendanceSlice.reducer;
