import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getRolesAsync,
  addRolesAsync,
  putRolesAsync,
  getRolesByIdAsync,
  deleteSingleRoleAsync,
} from "../async.api";

const initialState = {
  rolesLoader: false,
  getRolesList: [],
  addRoles: [],
  putRoles: [],
  getRolesById: [],
  deleteRole:[],
};

export const allRolesSlice = createSlice({
  name: "rolesSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getRolesAsync.pending,
        addRolesAsync.pending,
        putRolesAsync.pending,
        getRolesByIdAsync.pending,
        deleteSingleRoleAsync.pending
      ),
      (state) => {
        state.rolesLoader = true;
      }
    );

    builder.addMatcher(isAnyOf(getRolesAsync.fulfilled), (state, action) => {
      state.rolesLoader = false;
      state.getRolesList = action.payload;
    });

    builder.addMatcher(isAnyOf(addRolesAsync.fulfilled), (state, action) => {
      state.rolesLoader = false;
      console.log("addRoleSlice", action.payload);
      state.addRoles = action.payload;
    });

    builder.addMatcher(isAnyOf(putRolesAsync.fulfilled), (state, action) => {
      state.rolesLoader = false;
      state.putRoles = action.payload;
    });

    builder.addMatcher(isAnyOf(deleteSingleRoleAsync.fulfilled), (state, action) => {
      state.rolesLoader = false;
      state.deleteRole = action.payload;
    });

    builder.addMatcher(
      isAnyOf(getRolesByIdAsync.fulfilled),
      (state, action) => {
        state.rolesLoader = false;
        state.getRolesById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getRolesAsync.rejected,
        addRolesAsync.rejected,
        putRolesAsync.rejected,
        getRolesByIdAsync.rejected,
        deleteSingleRoleAsync.rejected
      ),
      (state) => {
        state.rolesLoader = false;
      }
    );
  },

  reducers: {
    emptyRoles: () => initialState,
  },
});

export const { emptyRoles } = allRolesSlice.actions;

export default allRolesSlice.reducer;
