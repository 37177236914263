// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  register: path(ROOTS_AUTH, "/register"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  verify: path(ROOTS_AUTH, "/verify"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  newPassword: path(ROOTS_AUTH, "/new-password"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page403: "/403",
  page404: "/404",
  page500: "/500",
  components: "/components",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, "/kanban"),
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  fileManager: path(ROOTS_DASHBOARD, "/files-manager"),
  permissionDenied: path(ROOTS_DASHBOARD, "/permission-denied"),
  blank: path(ROOTS_DASHBOARD, "/blank"),
  attendance: {
    root:path(ROOTS_DASHBOARD, "/attendance"),
    list:path(ROOTS_DASHBOARD, "/attendance/list")
  },
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
    banking: path(ROOTS_DASHBOARD, "/banking"),
    booking: path(ROOTS_DASHBOARD, "/booking"),
    monthly: path(ROOTS_DASHBOARD, "/monthly"),
    company: path(ROOTS_DASHBOARD, "/company"),
    branch: path(ROOTS_DASHBOARD, "/branch"),
    Roles: path(ROOTS_DASHBOARD, "/roles"),
    staff: path(ROOTS_DASHBOARD, "/staff"),
    customer: path(ROOTS_DASHBOARD, "/customer"),
   add: (id) => path(ROOTS_DASHBOARD, `/bankdetails/${id}/add`),
    file: path(ROOTS_DASHBOARD, "/file"),
    departman: path(ROOTS_DASHBOARD, "/departman"),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, "/mail"),
    all: path(ROOTS_DASHBOARD, "/mail/all"),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, "/chat"),
    new: path(ROOTS_DASHBOARD, "/chat/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
     list: path(ROOTS_DASHBOARD, "/user/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
     demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
 
  staff: {
    root: path(ROOTS_DASHBOARD, "/staff"),
    new: path(ROOTS_DASHBOARD, "/staff/new"),
    list: path(ROOTS_DASHBOARD, "/staff/list"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (name) => path(ROOTS_DASHBOARD, `/staff/${name}/edit`),
    bank: (id) => path(ROOTS_DASHBOARD, `/staff/${id}/bank`), 
    password: (id) => path(ROOTS_DASHBOARD, `/staff/${id}/password`),
    view: (id) => path(ROOTS_DASHBOARD, `/staff/${id}/view`),
    leavebalance: (id) => path(ROOTS_DASHBOARD, `/staff/${id}/leavebalance`),
    dailyAttendance: path(ROOTS_DASHBOARD, "/staff/dailyattendance"),
   },

  customer: {
    root: path(ROOTS_DASHBOARD, "/customer"),
    list:path(ROOTS_DASHBOARD, "/customer/list"),
    new: path(ROOTS_DASHBOARD, "/customer/new"),
    edit: (id) => path(ROOTS_DASHBOARD, `/customer/${id}/edit`),
    cards: path(ROOTS_DASHBOARD, "/customer/customer"),
  },

  resumemanagement: {
    root: path(ROOTS_DASHBOARD, "/resumemanagement"),
    list: path(ROOTS_DASHBOARD, "/resumemanagement/list"),
  },

  roles: {
    root: path(ROOTS_DASHBOARD, "/roles"),
    table: path(ROOTS_DASHBOARD, "/roles/table"),
    edit: (id) => path(ROOTS_DASHBOARD, `/roles/permission/${id}`),
  },

  master:{
    root: path(ROOTS_DASHBOARD, "/master"),
    department:path(ROOTS_DASHBOARD,'/master/department'),
     company:path(ROOTS_DASHBOARD,'/master/company'),
     branch:path(ROOTS_DASHBOARD,'/master/branch'),
     roles:path(ROOTS_DASHBOARD,'/master/roles'),
     states:path(ROOTS_DASHBOARD,'/master/states'),
     technology:path(ROOTS_DASHBOARD,'/master/technology'),
     services : path(ROOTS_DASHBOARD, "/master/services"),
  },

  
  states: {
    root: path(ROOTS_DASHBOARD, "/states"),
    table: path(ROOTS_DASHBOARD, "/states/table"),
  },

  leavemanagment: {
    root: path(ROOTS_DASHBOARD, "/leavemanagment"),
    table: path(ROOTS_DASHBOARD, "/leavemanagment/table"),
    new: path(ROOTS_DASHBOARD, "/leavemanagment/applyleave"),
    list: path(ROOTS_DASHBOARD, "/leavemanagment/allleave"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (id) => path(ROOTS_DASHBOARD, `/roles/permission/${id}`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  
  InventoryManagement: {
    root: path(ROOTS_DASHBOARD, "/inventorymanagement"),
    addAsset: path(ROOTS_DASHBOARD, "/inventorymanagement/addasset"),
    assignAsset: path(ROOTS_DASHBOARD, "/inventorymanagement/assignasset"),
    assetForm: path(ROOTS_DASHBOARD, "/InventoryManagement/form"),
    assetEdit: path(ROOTS_DASHBOARD, "/InventoryManagement/AssetEdit"),
    assetCreate: path(ROOTS_DASHBOARD, "/InventoryManagement/AssetCreate"),
    assettype: path(ROOTS_DASHBOARD, `/inventorymanagement/assettype`),
  },

  TaskManagement: {
    root: path(ROOTS_DASHBOARD, "/taskmanagement"),
    addTask: path(ROOTS_DASHBOARD, "/taskmanagement/addtask"),
    taskForm: path(ROOTS_DASHBOARD, "/TaskManagement/TaskForm"),
    changeStatus: path(ROOTS_DASHBOARD, "/TaskManagement/ChangeStatus"),
  },

  InvoiceManagement: {
    root: path(ROOTS_DASHBOARD, "/invoicemanagement"),
    list: path(ROOTS_DASHBOARD, "/invoicemanagement/list"),
    new: path(ROOTS_DASHBOARD, "/invoicemanagement/new"),
    Invoice: path(ROOTS_DASHBOARD, "/invoicemanagement/invoice"),
  },
  

  department: {
    root: path(ROOTS_DASHBOARD, "/department"),
    table: path(ROOTS_DASHBOARD, "/department/table"),
    create: path(ROOTS_DASHBOARD, "/department/create"),
    list: path(ROOTS_DASHBOARD, "/leavemanagment/allleave"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (id) => path(ROOTS_DASHBOARD, `/department/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },

  company: {
    root: path(ROOTS_DASHBOARD, "/company"),
    table: path(ROOTS_DASHBOARD, "/company/table"),
    create: path(ROOTS_DASHBOARD, "/company/create"),
    list: path(ROOTS_DASHBOARD, "/leavemanagment/allleave"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (id) => path(ROOTS_DASHBOARD, `/company/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  branch: {
    root: path(ROOTS_DASHBOARD, "/branch"),
    table: path(ROOTS_DASHBOARD, "/branch/table"),
    create: path(ROOTS_DASHBOARD, "/branch/create"),
    list: path(ROOTS_DASHBOARD, "/leavemanagment/allleave"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (id) => path(ROOTS_DASHBOARD, `/branch/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },

  expense: {
    root: path(ROOTS_DASHBOARD, "/expense"),
    table: path(ROOTS_DASHBOARD, "/expense/table"),
    add: path(ROOTS_DASHBOARD, "/expense/add"),
    list: path(ROOTS_DASHBOARD, "/leavemanagment/allleave"),
    cards: path(ROOTS_DASHBOARD, "/user/cards"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
    edit: (id) => path(ROOTS_DASHBOARD, `/expense/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },


  GeneralSetting: {
    root: path(ROOTS_DASHBOARD, "/generalsetting"),
    siteSetting: path(ROOTS_DASHBOARD, "/generalsetting/sitesetting"),
    holiday: path(ROOTS_DASHBOARD,"/generalsetting/holiday"),
    createpreviousattendance: path(ROOTS_DASHBOARD,"/generalsetting/createpreviousattendance"),
  },
 
  eCommerce: {
    root: path(ROOTS_DASHBOARD, "/e-commerce"),
    shop: path(ROOTS_DASHBOARD, "/e-commerce/shop"),
    list: path(ROOTS_DASHBOARD, "/e-commerce/list"),
    checkout: path(ROOTS_DASHBOARD, "/e-commerce/checkout"),
    new: path(ROOTS_DASHBOARD, "/e-commerce/product/new"),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-blazer-low-77-vintage/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/e-commerce/product/nike-air-force-1-ndestrukt"
    ),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, "/invoice"),
    list: path(ROOTS_DASHBOARD, "/invoice/list"),
    new: path(ROOTS_DASHBOARD, "/invoice/new"),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/view`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit"
    ),
    demoView: path(
      ROOTS_DASHBOARD,
      "/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5"
    ),
  },

  leadmanagement: {
    list: path(ROOTS_DASHBOARD, "/leadmanagement/list"),
    new: path(ROOTS_DASHBOARD, "/leadmanagement/new"),
    view: (id)=> path(ROOTS_DASHBOARD, `/leadmanagement/${id}/view`),
    edit: (id) => path(ROOTS_DASHBOARD, `/leadmanagement/${id}/edit`),
  },

  blog: {
    root: path(ROOTS_DASHBOARD, "/blog"),
    posts: path(ROOTS_DASHBOARD, "/blog/posts"),
    new: path(ROOTS_DASHBOARD, "/blog/new"),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(
      ROOTS_DASHBOARD,
      "/blog/post/apply-these-7-secret-techniques-to-improve-event"
    ),
  },
  payroll:{
    root:path(ROOTS_DASHBOARD, "/payroll"),
    table: path(ROOTS_DASHBOARD, "/payroll/table"),
  }
};

export const PATH_DOCS = {
  root: "https://docs.minimals.cc",
  changelog: "https://docs.minimals.cc/changelog",
};

export const PATH_ZONE_ON_STORE =
  "https://mui.com/store/items/zone-landing-page/";

export const PATH_MINIMAL_ON_STORE =
  "https://mui.com/store/items/minimal-dashboard/";

export const PATH_FREE_VERSION =
  "https://mui.com/store/items/minimal-dashboard-free/";

export const PATH_FIGMA_PREVIEW =
  "https://www.figma.com/file/OBEorYicjdbIT6P1YQTTK7/%5BPreview%5D-Minimal-Web.15.10.22?node-id=0%3A1";
