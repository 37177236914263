import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { applyleaveAsync ,getleaveAsync ,getallleaveAsync} from "../async.api";

 const initialState = {
    applyLoader:false,
    applyleaveData:[],
    applyError:[],


 }

export const applyLeaveSlice = createSlice({
    name:"applyleave",
    initialState,
    extraReducers:(builder)=>{
        builder.addMatcher(isAnyOf(applyleaveAsync.pending),(state)=>{
            state.applyLoader= true;
        });
        builder.addMatcher(isAnyOf(applyleaveAsync.fulfilled), (state, action) => {
            state.applyLoader = false;
            state.applyleaveData = action.payload;
          });
          builder.addMatcher(isAnyOf(applyleaveAsync.rejected), (state, action) => {
            state.applyLoader = false;
            state.applyError = action.payload;
          });
          
    },
    reducers: {
        emptyapplyleave: (state) => {
          return {
            ...initialState,
          };
        },
      },
})

 export const { emptyapplyleave } = applyLeaveSlice.actions;

export default applyLeaveSlice.reducer;