import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "./AxiosClient";

export const adminsignupAsync = createAsyncThunk(
  "admin/adminsignup",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `/adminsignup`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getloginAsync = createAsyncThunk(
  "admin/adminlogin",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/login`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const addStaffAsync = createAsyncThunk(
  "admin/regisStaff",

  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/admin/registerStaff`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// get all staff api
export const getStaffAsync = createAsyncThunk(
  "admin/getStaffAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/admin/users?searchAll=${payload.searchAll}&department=${payload.department}&branch=${payload.branch}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getDailyAttendanceAsync = createAsyncThunk(
  "admin/getDailyAttendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSpecificDashboardDetail?staffId=${payload?.staffId}&startDate=${payload?.startDate || ""}&endDate=${
        payload?.endDate || ""
      }`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getStaffAsignAsync = createAsyncThunk(
  "admin/getStaffAsign",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/admin/users?searchAll=${payload.searchAll}&department=${payload.department}&branch=${payload.branch}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// cut
export const putStaffAsync = createAsyncThunk(
  "admin/putStaff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/admin/user/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getStaffByIdAsync = createAsyncThunk(
  "admin/getStaffById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/admin/user/${payload.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const deleteStaffByIdAsync = createAsyncThunk(
  "admin/delUser/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/admin/user/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getSubStaffAsync = createAsyncThunk(
  "admin/getSubStaff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getSubStaffs/${payload.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const putAssignStaffAsync = createAsyncThunk(
  "admin/assignStaff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("PUT", `api/v1/addSubStaffs`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// updatding Active/Inactive status

export const putStaffStatusAsync = createAsyncThunk(
  "admin/activeInactive/staff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/statusChange/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getAttendAsync = createAsyncThunk(
  "admin/getAttendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/dashboardDetails?search=${payload.date || ""}&searchAll=${
        payload.searchAll || ""
      }&status=${payload.status}&name=${payload.name || ""}`,
      [],

      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putCheckedDataAsync = createAsyncThunk(
  "admin/putCheckedDataAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("PUT", `api/v1/updateManyStatus`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// updatding attendance status
export const putAttendanceStatusAsync = createAsyncThunk(
  "admin/putAttendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateStatus/${payload?.staffId}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Monthly attendance api
export const getMonthlyAttendanceAysnc = createAsyncThunk(
  "admin/getMonthlyAttendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/staffDashboard?page=${payload?.page}&limit=${
        payload.limit
      }&startDate=${payload?.startDate || ""}&endDate=${
        payload?.endDate || ""
      }&name=${payload?.name || ""}`,
      [],

      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//GET
export const getCSVDataForStaffAttendenceAsync = createAsyncThunk(
  "admin/getCSVDataForStaff",
  async (payload, { rejectWithValue }) => {
    try {
      const token = "Bearer " + localStorage.getItem("token") ?? null;
      const url = `${process.env.REACT_APP_BASE_URL}api/v1/getCSVDataForStaffAttendence?search=${payload}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&token=${token}`;
      window.open(url, "_blank");
      return "Success"; 
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Bank Post api
export const addBankDetailsAsync = createAsyncThunk(
  "admin/addBankDetails",

  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/bank`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Bank put api
export const putBankDetailsAsync = createAsyncThunk(
  "admin/putBankDetails",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("PUT", `api/v1/bank/${payload?.userid}`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get Date Of Birth Api
export const getUserDOBAsync = createAsyncThunk(
  "admin/getUserDOB",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getUsersBasedOnDob?dobDate=${payload?.search}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getUserAnniversaryAsync = createAsyncThunk(
  "admin/getUserAnniversary",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getUsersBasedOnJoiningDate?joiningDate=${payload?.search}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// dashboad lead
export const getLeadTodayFollowAsync = createAsyncThunk(
  "admin/getLeadTodayFollow",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getfollowUps?type=today`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
export const getLeadUpcomingFollowUpAsync = createAsyncThunk(
  "admin/getLeadUpcomingFollowUp",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getfollowUps?type=upcoming`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// Bank Get api

export const getBankDetailsAsync = createAsyncThunk(
  "admin/getBankDetails",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/bank/${payload?.userid}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
// get user details by id api
export const getNavAdminIdAsync = createAsyncThunk(
  "admin/navAdminIcon",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/me/${payload?.staffId}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// Department get api
export const getDepartmentAsync = createAsyncThunk(
  "admin/getAlldepartment",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllDepartment?department=${payload.department || ""}`,

      [],

      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// department post api

export const DepartmentpostAsync = createAsyncThunk(
  "admin/adddepartment",

  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addDepartment`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// Department put api
export const putDepartmentAsync = createAsyncThunk(
  "admin/editdepartment",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateDepartment/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Department Delete api
export const deleteDepartmentByIdAsync = createAsyncThunk(
  "admin/department/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/department/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Department getbyid api
export const getDepartmentByIdAsync = createAsyncThunk(
  "admin/getbyiddepartment/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getDepartmentById/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// // asset getType api
export const getAssetTypeAsync = createAsyncThunk(
  "admin/getAllassetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllAssetType`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// // asset get api
export const getAssetAsync = createAsyncThunk(
  "admin/getAllasset",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllAsset`,

      [],

      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Asset post api

export const postAssetAsync = createAsyncThunk(
  "admin/postasset",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addAsset`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// // asset put api
export const putAssetAsync = createAsyncThunk(
  "admin/editasset",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateAsset/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// asset Delete api
export const deleteAssetByIdAsync = createAsyncThunk(
  "admin/deleteasset/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",

      `api/v1/deleteAsset/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// // asset getbyid api
export const getAssetByIdAsync = createAsyncThunk(
  "admin/getbyidasset/",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",

      `api/v1/getAssetById/${payload.id}`,

      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// // task getType api
export const getTaskUserAsync = createAsyncThunk(
  "admin/getAllTaskUser",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/admin/users`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// // task get api
export const getTaskAsync = createAsyncThunk(
  "admin/getAllTask",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllTask`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// // task post api

export const postTaskAsync = createAsyncThunk(
  "admin/poststask",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addTask`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// // task put api
export const putTaskAsync = createAsyncThunk(
  "admin/editstask",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateTask/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// task Delete api
export const deleteTaskByIdAsync = createAsyncThunk(
  "admin/deletetask/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteTask/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// // task getbyid api
export const getTaskByIdAsync = createAsyncThunk(
  "admin/getbyidTask/",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getTaskById/${payload?.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const putTaskStatusAsync = createAsyncThunk(
  "admin/putAllTaskStatus",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateTaskByStaff/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// get leave by id
export const getleaveAsync = createAsyncThunk(
  "admin/users",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getLeave/${payload.staffId}?page=${payload?.page}&limit=${payload.limit}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
// apply leave by id api
export const applyleaveAsync = createAsyncThunk(
  "admin/applyleave/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/applyLeave`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
// get all leave
export const getalleaveAsync = createAsyncThunk(
  "admin/leaves",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllLeave?page=${payload?.page}&limit=${
        payload.limit
      }&startDate=${payload?.startDate || ""}&endDate=${
        payload?.endDate || ""
      }&name=${payload?.name || ""}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putleaveAsync = createAsyncThunk(
  "admin/users",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateLeave/${payload?.staffId}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// copmany post api
export const branchPostAsync = createAsyncThunk(
  "admin/Addbranch",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addBranch`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//company get api
export const getAllBranchAsync = createAsyncThunk(
  "admin/getAllBranchData",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllBranches?branch=${payload.branch || ""}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// company get by id
export const getBranchByIdAsync = createAsyncThunk(
  "admin/getbyidcompanybranch",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getBranchById/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// /compny put api

export const putBranchByAsync = createAsyncThunk(
  "admin/putBranchByAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateBranch/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Expense Add api

export const addExpensesAsync = createAsyncThunk(
  "admin/addExpense",

  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addExpense`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
//delete company by id
export const deleteBranchByIdAsync = createAsyncThunk(
  "admin/company/id",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteBranch/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
// Expense get api
export const getExpensesAsync = createAsyncThunk(
  "admin/getExpense",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllExpenses?page=${payload.page}&limit=${
        payload.limit
      }&startDate=${payload?.startDate || ""}&endDate=${
        payload?.endDate || ""
      }&search=${payload?.search || ""}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Expense getById api
export const getExpenseByIdAsync = createAsyncThunk(
  "admin/getExpenseById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getExpenseById/${payload.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Expense put api
export const putExpensesAsync = createAsyncThunk(
  "admin/putExpense",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateExpense/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putMultipleStatusAsync = createAsyncThunk(
  "admin/putMultipleStatus",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("PUT", `api/v1/updateMultipleStatus`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// Expense Delete page
export const deleteExpensesAsync = createAsyncThunk(
  "admin/delExpense",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteExpense/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//GET Roles
export const getRolesAsync = createAsyncThunk(
  "admin/getAllRoles",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllRoles`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//ADD ROLES
export const addRolesAsync = createAsyncThunk(
  "admin/addRoles",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/createRole`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//getBy Id Roles
export const getRolesByIdAsync = createAsyncThunk(
  "admin/getRolesById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getSingleRole/${payload.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//put roles
export const putRolesAsync = createAsyncThunk(
  "admin/putRoles",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateRole/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//delete roles
export const deleteSingleRoleAsync = createAsyncThunk(
  "admin/deleteRole",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteRole/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//GET All States (GET ALL LIST API)
export const getAllStatesAsync = createAsyncThunk(
  "admin/getAllStates",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllState`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//ADD States (POST API)
export const addStatesAsync = createAsyncThunk(
  "admin/addStates",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addState`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//getBy Id States
export const getStatesByIdAsync = createAsyncThunk(
  "admin/getStatesById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getStateById/${payload.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//put states
export const putStatesAsync = createAsyncThunk(
  "admin/putStates",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateState/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const postStatesAsync = createAsyncThunk(
  "admin/postStates",

  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addState`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

// Delete

export const deleteStateAsync = createAsyncThunk(
  "admin/deleteState",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteState/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Payroll

export const getPayrollAsync = createAsyncThunk(
  "admin/getPayroll",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getMonthlyPaymentDetailsOfStaff?month=${payload.month}&year=${payload.year}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// payroll monthly salary
export const getPayrollSalaryAsync = createAsyncThunk(
  "admin/getPayrollSalary",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getMonthlyPaymentDetails?month=${payload?.month}&year=${payload?.year}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//PuT Payroll
export const putPayrollAsync = createAsyncThunk(
  "admin/putRoles",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/payMonthlyPaymentToStaff/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// AssetType

// GET
export const getAllAssetTypeAsync = createAsyncThunk(
  "admin/getAssetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllAssetType`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//PUT
export const putAssetTypeAsync = createAsyncThunk(
  "admin/putAssetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateAssetType/${payload?.id}`,
      payload.body,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Add Asset Type (Post)

export const addAssetTypeAsync = createAsyncThunk(
  "admin/addAssetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addAssetType`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get by id

export const getAssetTypeByIdAsync = createAsyncThunk(
  "admin/getAssetTypeById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAssetTypeById/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Delete

export const deleteAssetTypeAsync = createAsyncThunk(
  "admin/deleteAssetType",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteAssetType/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// Assign Asset section start -----------
//getAssignassetList (Get)
export const getAssignAssetAsync = createAsyncThunk(
  "admin/getAssignAssetAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllAssignAsset?assignToId=${payload?.assignToId}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
//AddassignAsset (Post)
export const addAssignAssetAsync = createAsyncThunk(
  "admin/addAssignAssetAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addAssignAsset`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get by id
export const getAssignAssetByIdAsync = createAsyncThunk(
  "admin/getAssignAssetById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAssignAssetById/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//put
export const updateAssignAssetAsync = createAsyncThunk(
  "admin/updateAssignAssetAsync/staff",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/returnAssignAsset/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//getAll Staff-----
export const getAllStaffAsync = createAsyncThunk(
  "admin/getAllStaffAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/admin/users`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
// Assign Asset section  End -----------
export const getAllHolidayAsync = createAsyncThunk(
  "admin/getAllHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllHolidays?year=${payload.year}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getSingleHolidayAsync = createAsyncThunk(
  "admin/getSingleHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSingleHoliday/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const addHolidayAsync = createAsyncThunk(
  "admin/addHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addHoliday`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const deleteHolidayByIdAsync = createAsyncThunk(
  "admin/deleteHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteSingleHoliday/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putHolidayAsync = createAsyncThunk(
  "admin/putHoliday",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateHoliday/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// create Previous Attendance
export const postPreviousAttendanceAsync = createAsyncThunk(
  "admin/postpreviousattendance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "POST",
      `api/v1/createAttendenceManually`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// ============================== Customer Section ==============================
//Get All Customer--------------
export const getAllCustomerListAsync = createAsyncThunk(
  "admin/getAllCustomerList",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllCustomer`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get Single Customer by Id--------------
export const getCustomerByIdAsync = createAsyncThunk(
  "admin/getCustomerById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getCustomerById/${payload?.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Post or Add Customer--------------------
export const addCustomerAsync = createAsyncThunk(
  "admin/addCustomer",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addCustomer`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Put or Update Customer------------------
export const putCustomerAsync = createAsyncThunk(
  "admin/putCustomer",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateCustomer/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Delete Customer---------------------------
export const deleteCustomerAsync = createAsyncThunk(
  "admin/deleteCustomer",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteCustomer/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//change password---
export const putChangePaswordAsync = createAsyncThunk(
  "admin/putChangePaswordAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("PUT", `api/v1/password/update`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Technology Section-----------------------------
//Get All technology List-----------
export const getAllTechnologyAsync = createAsyncThunk(
  "admin/getAllTechnologyList",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllTechnologies`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get technology By ID-----------
export const getTechnologyByIdAsync = createAsyncThunk(
  "admin/getTechnologyById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getTechnologyById/${payload?.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//AddTechnology (Post)-------------------
export const postTechnologyAsync = createAsyncThunk(
  "admin/postTechnology",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addTechnology`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Update Technology-----------------------
export const putTechnologyAsync = createAsyncThunk(
  "admin/putTechnology",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateTechnology/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Delete Technology---------------------------
export const deleteTechnologyAsync = createAsyncThunk(
  "admin/deleteTechnology",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteTechnology/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//------------------------------------Resume Management------------------------------
//Get All Resume Management List-----------
export const getAllResumeListAsync = createAsyncThunk(
  "admin/getAllResumeList",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllResume`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get Resume By ID-----------
export const getResumeByIdAsync = createAsyncThunk(
  "admin/getResumeById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getResumeById/${payload?.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Add Resume (Post)-------------------
export const postResumeAsync = createAsyncThunk(
  "admin/postResume",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addResume`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Update Resume ( PUT )-----------------------
export const putResumeAsync = createAsyncThunk(
  "admin/putResume",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateResume/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Delete Resume---------------------------
export const deleteResumeAsync = createAsyncThunk(
  "admin/deleteResume",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteResume/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

// All company
export const getAllCompanyAsync = createAsyncThunk(
  "admin/getAllCompany",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAllCompanies?companyName=${payload.companyName || ""}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getCompanyByIdAsync = createAsyncThunk(
  "admin/getCompanyById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSingleCompany/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const putCompanyByAsync = createAsyncThunk(
  "admin/putCompany",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateCompany/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const deleteCompanyByIdAsync = createAsyncThunk(
  "admin/deletecompany",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteSingleCompany/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const addCompanyAsync = createAsyncThunk(
  "admin/addCompany",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addCompany`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
//invoice module start--------
export const addInvoiceAsync = createAsyncThunk(
  "admin/addInvoice",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addInvoice`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getAllInvoicesAsync = createAsyncThunk(
  "admin/getAllInvoices",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllInvoice`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const updateInvoiceAsync = createAsyncThunk(
  "admin/updateInvoiceAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateInvoice/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const deleteInvoiceAsync = createAsyncThunk(
  "admin/deleteInvoiceAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteInvoice/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getInvoiceByIdAsync = createAsyncThunk(
  "admin/getInvoiceByIdAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSingleInvoice/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const getInvoicesStatusDetailsAsync = createAsyncThunk(
  "admin/getInvoicesStatusDetails",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getInvoicesStatusDetails`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getCurrentInvoiceNumberAsync = createAsyncThunk(
  "admin/getCurrentInvoiceNumberAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getCurrentInvoiceNumber`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const updateInvoiceStatusAsync = createAsyncThunk(
  "admin/updateInvoiceStatus",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateInvoiceStatus/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
//invoice module  End--------

//get services
export const getAllServicesAsync = createAsyncThunk(
  "admin/getAllServices",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllServices`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//GetByID Service
export const getServiceByIdAsync = createAsyncThunk(
  "admin/getServiceById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSingleService/${payload?.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Addservice
export const postServiceAsync = createAsyncThunk(
  "admin/postService",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addService`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Update service
export const putServiceAsync = createAsyncThunk(
  "admin/putService",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateService/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Delete service
export const deleteServiceAsync = createAsyncThunk(
  "admin/deleteService",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteSingleService/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
//permission module start---->>>>
export const getAllPermissionAsync = createAsyncThunk(
  "admin/getAllPermission",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllPermissions`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getSinglePermissionAsync = createAsyncThunk(
  "admin/getSinglePermission",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getSinglePermission/${payload.id}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const getAllRoutesAsync = createAsyncThunk(
  "admin/getAllRoutesAsync",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllRoutes`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const createPermissionAsync = createAsyncThunk(
  "admin/createPermission",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/createPermission`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const getPermissionBasedOnRoleAsync = createAsyncThunk(
  "admin/getPermissionBasedOnRole",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getPermissionBasedOnRole`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
//permission module  end---->>>>>>>

// ============================== Lead Management Section ==============================
//Get All Lead--------------
export const getAllLeadsListAsync = createAsyncThunk(
  "admin/getAllLeadsList",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getAllLeads`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Get Single Lead by Id--------------
export const getLeadByIdAsync = createAsyncThunk(
  "admin/getLeadById",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("GET", `api/v1/getLeadById/${payload?.id}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Post or Add Lead--------------------
export const addNewLeadAsync = createAsyncThunk(
  "admin/addNewLead",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addLead`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

//Put or Update Lead------------------
export const updateLeadAsync = createAsyncThunk(
  "admin/updateLead",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateLead/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Delete Lead---------------------------
export const deleteLeadAsync = createAsyncThunk(
  "admin/deleteLead",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteLead/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
export const postFollowUpAsync = createAsyncThunk(
  "admin/postFollowUp",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient("POST", `api/v1/addFollowUps`, payload, {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);

export const deleteFollowUpAsync = createAsyncThunk(
  "admin/deleteFollowUp",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "DELETE",
      `api/v1/deleteFollowUp/${payload.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

export const updateFollowUpAsync = createAsyncThunk(
  "admin/updateFollowUp",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "PUT",
      `api/v1/updateFollowUp/${payload?.id}`,
      payload,
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);

//Leave Balance

export const getLeaveBalanceAsync = createAsyncThunk(
  "admin/getLeaveBalance",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    return await AxiosClient(
      "GET",
      `api/v1/getAlllogs?staffId=${payload?.staffId}&month=${payload?.month}&year=${payload?.year}`,
      [],
      {
        dispatch,
        rejectWithValue,
        fulfillWithValue,
      }
    );
  }
);
