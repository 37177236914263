import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { postPreviousAttendanceAsync } from "../async.api";

const initialState = {
  previousAttendanceLoader: false,
  postAttendances: [],
};

const previousAttendances = createSlice({
  name: "createPreviousAttendances",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(postPreviousAttendanceAsync.pending),
      (state) => {
        state.previousAttendanceLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(postPreviousAttendanceAsync.fulfilled),
      (state, action) => {
        state.previousAttendanceLoader = false;
        state.postAttendances = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(postPreviousAttendanceAsync.rejected),
      (state) => {
        state.previousAttendanceLoader = true;
      }
    );
  },
});

export default previousAttendances.reducer;
