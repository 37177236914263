import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllTechnologyAsync,
  getTechnologyByIdAsync,
  putTechnologyAsync,
  postTechnologyAsync,
  deleteTechnologyAsync,
} from "../async.api";

const initialState = {
    technologyLoader : false,
    getAllTechnologyList : [],
    getTechnologyById: [],
    putTechnology: [],
    postTechnology: [],
    deleteTechnology: [],
}


const technologySlice = createSlice({
  name: "technology",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllTechnologyAsync.pending,
        getTechnologyByIdAsync.pending,
        putTechnologyAsync.pending,
        postTechnologyAsync.pending,
        deleteTechnologyAsync.pending,
      ),
      (state) => {
        state.technologyLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllTechnologyAsync.fulfilled),
      (state, action) => {
        state.technologyLoader = false;
        state.getAllTechnologyList = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getTechnologyByIdAsync.fulfilled),
      (state, action) => {
        state.technologyLoader = false;
        state.getTechnologyById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(putTechnologyAsync.fulfilled),
      (state, action) => {
        state.technologyLoader = false;
        state.putTechnology = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(postTechnologyAsync.fulfilled),
      (state, action) => {
        state.technologyLoader = false;
        state.postTechnology = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(deleteTechnologyAsync.fulfilled),
      (state, action) => {
        state.technologyLoader = false;
        state.deleteTechnology = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllTechnologyAsync.rejected,
        getTechnologyByIdAsync.rejected,
        putTechnologyAsync.rejected,
        postTechnologyAsync.rejected,
        deleteTechnologyAsync.rejected,
      ),
      (state) => {
        state.technologyLoader = true;
      }
    );
  },

  reducers: {
    emptyTechnology: () => initialState,
  },
});


export const { emptyTechnology } = technologySlice.actions;

export default technologySlice.reducer;