// https://documentation.onesignal.com/docs/web-sdk docs

function funcOneSignal() {

    window.OneSignalDeferred = window.OneSignalDeferred || [];
    // OneSignalDeferred.push(async function (OneSignal) { // we can do this inside script file inside public folder but inside src no
    window.OneSignalDeferred.push(async function (OneSignal) {

        await OneSignal.init({
            appId: process.env.REACT_APP_ONE_SIGNAL_CONSOLE_APP_ID,
        });


        if ((
            !localStorage.getItem("oneSignalSubscriptionId")
            ||
            localStorage.getItem("oneSignalSubscriptionId") === "undefined"
        )
            && localStorage.getItem("token")) {

            let retryAttempts = 0;
            const maxRetryAttempts = 10;

            async function checkForSubscriptionId() {
                if (OneSignal?.User?.PushSubscription?.id) {
                    localStorage.setItem("oneSignalSubscriptionId", OneSignal.User.PushSubscription.id)
                    let headers = {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    };
                    fetch(`${process.env.REACT_APP_PROD_URL}/api/v1/setSubscriptionIdOfOneSignalOfUser`, {
                        method: 'POST',
                        headers: headers,
                        body: JSON.stringify({ oneSignalSubscriptionId: OneSignal.User.PushSubscription.id }),
                    })
                        .then(data => {
                            console.log('Notification sent successfully:', data);
                        })
                        .catch(error => {
                            console.error('Error sending notification:', error);
                        });
                }
                else if (retryAttempts < maxRetryAttempts) {
                    // If the ID is not available and we haven't reached the maximum retry attempts, wait and check again after a delay
                    retryAttempts++;
                    setTimeout(checkForSubscriptionId, 1000); // Adjust the delay as needed
                }
                else {
                    console.error('Maximum retry attempts reached. Unable to get OneSignal subscription ID.');
                }

            }

            // Start the initial check for the subscription ID
            checkForSubscriptionId();
        }


    });

}
export default funcOneSignal;


// OneSignal.Notifications.addEventListener("permissionChange", () => {

//     console.log(OneSignal.User) //here i am not getting token inside user why ?
//     console.log(OneSignal.User.PushSubscription.id)

// });