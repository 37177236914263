import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getAllStatesAsync,
  getStatesByIdAsync,
  postStatesAsync,
  putStatesAsync,
  deleteStateAsync,
} from "../async.api";

const initialState = {
  stateLoader: false,
  getAllStates: [],
  getStatesById: [],
  postStates: [],
  putStates: [],
  deleteState: [],
};

const stateSlice = createSlice({
  name: "state",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllStatesAsync.pending,
        getStatesByIdAsync.pending,
        postStatesAsync.pending,
        putStatesAsync.pending,
        putStatesAsync.pending,
        deleteStateAsync.pending
      ),
      (state) => {
        state.stateLoader = true;
      }
    );

    // assetDataById
    builder.addMatcher(
      isAnyOf(getAllStatesAsync.fulfilled),
      (state, action) => {
        state.stateLoader = false;
        state.getAllStates = action.payload;
      }
    );
    
    builder.addMatcher(isAnyOf(getStatesByIdAsync.fulfilled), (state, action) => {
      state.stateLoader = false;
      state.getStatesById = action.payload;
    })
    
    builder.addMatcher(isAnyOf(postStatesAsync.fulfilled), (state, action) => {
      state.stateLoader = false;
      state.postStates = action.payload;
    });

    

    builder.addMatcher(isAnyOf(deleteStateAsync.fulfilled), (state, action) => {
      state.stateLoader = false;
      state.deleteState = action.payload;
    });

    builder.addMatcher(isAnyOf(putStatesAsync.fulfilled), (state, action) => {
      state.stateLoader = false;
      state.putStates = action.payload;
    });

    builder.addMatcher(
      isAnyOf(
        getAllStatesAsync.rejected,
        getStatesByIdAsync.rejected,
        postStatesAsync.rejected,
        putStatesAsync.rejected,
        putStatesAsync.rejected,
        deleteStateAsync.rejected,
      ),
      (state) => {
        state.stateLoader = true;
      }
    );
  },

  reducers: {
    emptyState: () => initialState,
  },
});

export const { emptyState } = stateSlice.actions;
export default stateSlice.reducer;
