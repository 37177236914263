import { memo } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Leave from './leave.png'

// ----------------------------------------------------------------------

function CheckInIllustration({ ...other }) {
  const theme = useTheme();

  const PRIMARY_LIGHTER = theme.palette.primary.lighter;

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const PRIMARY_DARKER = theme.palette.primary.darker;

  return (
    <img alt='Leave' src={Leave}/>
  );
}

export default memo(CheckInIllustration);
