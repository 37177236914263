import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addInvoiceAsync,
  getAllInvoicesAsync,
  updateInvoiceAsync,
  getInvoiceByIdAsync,
  getInvoicesStatusDetailsAsync,
  getCurrentInvoiceNumberAsync,
  updateInvoiceStatusAsync,
  getSingleInvoiceAsync
} from "../async.api";

const initialState = {
  allInvoiceLoader: false,
  invoiceData: [],
  addInvoice: [],
  allInvoiceError: [],
  updateInvoice: [],
  invoiceById: [],
  statusDetails:[],
  currentInvoiceNumber:[],
  updateInvoiceStatus:[],
  getSingleInvoice:[]
};

export const allInvoiceSlice = createSlice({
  name: "allInvoiceSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addInvoiceAsync.pending,
        getAllInvoicesAsync.pending,
        updateInvoiceAsync.pending,
        getCurrentInvoiceNumberAsync.pending
      ),
      (state) => {
        state.allInvoiceLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(addInvoiceAsync.fulfilled), (state, action) => {
      state.allInvoiceLoader = false;
      state.addInvoice = action.payload;
    });
    builder.addMatcher(
      isAnyOf(getAllInvoicesAsync.fulfilled),
      (state, action) => {
        state.allInvoiceLoader = false;
        state.invoiceData = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInvoiceAsync.fulfilled),
      (state, action) => {
        state.allInvoiceLoader = false;
        state.updateInvoice = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getInvoiceByIdAsync.fulfilled),
      (state, action) => {
        state.allInvoiceLoader = false;
        state.invoiceById = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getInvoicesStatusDetailsAsync.fulfilled),
      (state, action) => {
        state.allInvoiceLoader = false;
        state.statusDetails = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getCurrentInvoiceNumberAsync.fulfilled),
      (state, action) => {
        state.allInvoiceLoader = false;
        state.currentInvoiceNumber = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateInvoiceStatusAsync.fulfilled),
      (state, action) => {
        state.allInvoiceLoader = false;
        state.updateInvoiceStatus = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(
        addInvoiceAsync.rejected,
        getAllInvoicesAsync.rejected,
        updateInvoiceAsync.rejected,
        getCurrentInvoiceNumberAsync.rejected
      ),
      (state, action) => {
        state.allInvoiceLoader = false;
        state.allInvoiceError = action.payload;
      }
    );
  },
  reducers: {
    emptyALLInvoice: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyALLInvoice } = allInvoiceSlice.actions;
export default allInvoiceSlice.reducer;
