import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "src/redux/AxiosClient";
// import moment from "moment/moment";
export const getDashboardAsync = createAsyncThunk(
  "admin/getDashboard",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    // return await AxiosClient("GET", `api/v1/count?search=${moment().format("YYYY-MM-DD")}`, [], {
    return await AxiosClient("GET", `api/v1/count?search=${payload?.search}`, [], {
      dispatch,
      rejectWithValue,
      fulfillWithValue,
    });
  }
);
