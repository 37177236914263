import { createSlice, isAnyOf } from "@reduxjs/toolkit";

import {
  getAllResumeListAsync,
  getResumeByIdAsync,
  putResumeAsync,
  postResumeAsync,
  deleteResumeAsync,
} from "../async.api";

const initialState = {
  resumeLoader: false,
  getAllResumeList: [],
  getResumeById: [],
  putResume: [],
  postResume: [],
  deleteResume: [],
};

const resumeManagementSlice = createSlice({
  name: "resume",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getAllResumeListAsync.pending,
        getResumeByIdAsync.pending,
        putResumeAsync.pending,
        postResumeAsync.pending,
        deleteResumeAsync.pending
      ),
      (state) => {
        state.resumeLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllResumeListAsync.fulfilled),
      (state, action) => {
        state.resumeLoader = false;
        state.getAllResumeList = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getResumeByIdAsync.fulfilled),
      (state, action) => {
        state.resumeLoader = false;
        state.getResumeById = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(putResumeAsync.fulfilled), (state, action) => {
      state.resumeLoader = false;
      state.putResume = action.payload;
    });

    builder.addMatcher(isAnyOf(postResumeAsync.fulfilled), (state, action) => {
      state.resumeLoader = false;
      state.postResume = action.payload;
    });

    builder.addMatcher(
      isAnyOf(deleteResumeAsync.fulfilled),
      (state, action) => {
        state.resumeLoader = false;
        state.deleteResume = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getAllResumeListAsync.rejected,
        getResumeByIdAsync.rejected,
        putResumeAsync.rejected,
        postResumeAsync.rejected,
        deleteResumeAsync.rejected,
      ),
      ( state ) => {
        state.resumeLoader = true;
      }
    );
  },

  reducers:{
    emptyResume : () => initialState,
  }
});

export const { emptyResume } = resumeManagementSlice.actions;

export default resumeManagementSlice.reducer;