import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getDashboardAsync } from "./dashboardAsync.api";
import { getPayrollSalaryAsync } from "src/redux/async.api";
import { getUserDOBAsync } from "src/redux/async.api";
import { getUserAnniversaryAsync } from "src/redux/async.api";
import {
  getLeadUpcomingFollowUpAsync,
  getLeadTodayFollowAsync,
} from "src/redux/async.api";

const initialState = {
  dobLoader: false,
  anniversaryLoader: false,
  dashboardLoader: false,
  dashboard: [],
  dashboardSalary: [],
  getUserDOB: [],
  getUserAnniversary: [],
  getLeadToday: [],
  getLeadUpcoming: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        getDashboardAsync.pending,
        getLeadUpcomingFollowUpAsync.pending,
        getLeadTodayFollowAsync.pending
      ),
      (state) => {
        state.dashboardLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(getUserAnniversaryAsync.pending), (state) => {
      state.anniversaryLoader = true;
    });
    builder.addMatcher(isAnyOf(getUserDOBAsync.pending), (state) => {
      state.dobLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getDashboardAsync.fulfilled),
      (state, action) => {
        state.dashboardLoader = false;
        state.dashboard = action.payload.data;
      }
    );
    builder.addMatcher(isAnyOf(getUserDOBAsync.fulfilled), (state, action) => {
      state.dobLoader = false;
      state.getUserDOB = action.payload;
    });
    builder.addMatcher(
      isAnyOf(getLeadTodayFollowAsync.fulfilled),
      (state, action) => {
        state.dashboardLoader = false;
        state.getLeadToday = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getLeadUpcomingFollowUpAsync.fulfilled),
      (state, action) => {
        state.dashboardLoader = false;
        state.getLeadUpcoming = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getUserAnniversaryAsync.fulfilled),
      (state, action) => {
        state.anniversaryLoader = false;
        state.getUserAnniversary = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        getDashboardAsync.rejected,
        getLeadUpcomingFollowUpAsync.rejected,
        getLeadTodayFollowAsync.rejected
      ),
      (state, action) => {
        state.dashboardLoader = false;
      }
    );
    builder.addMatcher(isAnyOf(getUserDOBAsync.rejected), (state) => {
      state.dobLoader = false;
    });
    builder.addMatcher(isAnyOf(getUserAnniversaryAsync.rejected), (state) => {
      state.anniversaryLoader = false;
    });

    builder.addMatcher(isAnyOf(getPayrollSalaryAsync.pending), (state) => {
      state.dashboardLoader = true;
    });

    builder.addMatcher(
      isAnyOf(getPayrollSalaryAsync.fulfilled),
      (state, action) => {
        state.dashboardLoader = false;
        state.dashboardSalary = action.payload.data;
      }
    );

    builder.addMatcher(
      isAnyOf(getPayrollSalaryAsync.rejected),
      (state, action) => {
        state.dashboardLoader = false;
      }
    );
  },

  reducers: {
    emptydashboard: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptydashboard } = dashboardSlice.actions;
export default dashboardSlice.reducer;
