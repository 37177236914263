import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addCompanyAsync,
  deleteCompanyByIdAsync,
  putCompanyByAsync,
  getCompanyByIdAsync,
  getAllCompanyAsync
} from "../async.api";

const initialState = {
  companyInfoLoader: false,
  addCompanyData: [],
  deleteCompanyData: [],
  editCompanyData: [],
  companyDatabyid: [],
  getAllCompanyData:[]
};

export const companySlice = createSlice({
  name: "companyDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addCompanyAsync.pending,
        getAllCompanyAsync.pending,
        deleteCompanyByIdAsync.pending,
        putCompanyByAsync.pending,
        getCompanyByIdAsync.pending
      ),
      (state) => {
        state.companyInfoLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(addCompanyAsync.fulfilled), (state, action) => {
      state.companyInfoLoader = false;
      state.addCompanyData=action.payload;
    });
    //all company
    builder.addMatcher(
      isAnyOf(getAllCompanyAsync.fulfilled),
      (state, action) => {
        state.companyInfoLoader = false;
        state.getAllCompanyData = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(deleteCompanyByIdAsync.fulfilled),
      (state, action) => {
        state.companyInfoLoader = false;
        state.deleteCompanyData = action.payload;
      }

    );

    builder.addMatcher(
      isAnyOf(putCompanyByAsync.fulfilled),
      (state, action) => {
        state.companyInfoLoader = false;
        state.editCompanyData = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getCompanyByIdAsync.fulfilled),
      (state, action) => {
        state.companyInfoLoader = false;
        state.companyDatabyid = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        addCompanyAsync.rejected,
        getAllCompanyAsync.rejected,
        deleteCompanyByIdAsync.rejected,
        putCompanyByAsync.rejected,
        getCompanyByIdAsync.rejected
      ),
      (state, action) => {
        state.companyInfoLoader = false;
      }
    );
  },
  reducers: {
    emptycompanyData: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {emptycompanyData} = companySlice.actions;

export default companySlice.reducer;
