import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
    branchPostAsync,
  getAllBranchAsync,
  deleteBranchByIdAsync,
  putBranchByAsync,
  getBranchByIdAsync,
} from "../async.api";

const initialState = {
  companyInfoLoader: false,
  companyDataadd: [],
  getAllBranchData: [],
  deleteBranchData: [],
  editBranchData: [],
  companyDatabyid: [],
  // getAllCompanyData:[]  // required later
};

export const branchSlice = createSlice({
  name: "branchDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        branchPostAsync.pending,
        getAllBranchAsync.pending,
        deleteBranchByIdAsync.pending,
        putBranchByAsync.pending,
        getBranchByIdAsync.pending,
      ),
      (state) => {
        state.companyInfoLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(branchPostAsync.fulfilled), (state, action) => {
      state.companyInfoLoader = false;
      state.companyDataadd = action.payload;
    });
    builder.addMatcher(
      isAnyOf(getAllBranchAsync.fulfilled),
      (state, action) => {
        state.companyInfoLoader = false;
        state.getAllBranchData = action.payload;
      }
    );
    //all company
    // builder.addMatcher(
    //   isAnyOf(getAllBranchAsync.fulfilled),
    //   (state, action) => {
    //     state.companyInfoLoader = false;            // required later
    //     state.getAllCompanyData = action.payload;
    //   }
    // );
    builder.addMatcher(
      isAnyOf(deleteBranchByIdAsync.fulfilled),
      (state, action) => {
        state.companyInfoLoader = false;
        state.deleteBranchData = action.payload;
      }

    );

    builder.addMatcher(
      isAnyOf(putBranchByAsync.fulfilled),
      (state, action) => {
        state.companyInfoLoader = false;
        state.editBranchData = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getBranchByIdAsync.fulfilled),
      (state, action) => {
        state.companyInfoLoader = false;
        state.companyDatabyid = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(
        branchPostAsync.rejected,
        getAllBranchAsync.rejected,
        deleteBranchByIdAsync.rejected,
        putBranchByAsync.rejected,
        getBranchByIdAsync.rejected
      ),
      (state, action) => {
        state.companyInfoLoader = false;
      }
    );
  },
  reducers: {
    emptycompanyData: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {emptycompanyData} = branchSlice.actions;

export default branchSlice.reducer;
