import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addBankDetailsAsync,
  putBankDetailsAsync,
  getBankDetailsAsync,
} from "../async.api";

const initialState = {
  addingBankInfoLoader: false,
  addBankData: [],
  addBankInfoError: [],
  putBankData: [],
  getBankDataById: [],
};

export const bankDetailsSlice = createSlice({
  name: "bankDetails",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        addBankDetailsAsync.pending,
        putBankDetailsAsync.pending,
        getBankDetailsAsync.pending
      ),
      (state) => {
        state.addingBankInfoLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(addBankDetailsAsync.fulfilled),
      (state, action) => {
        state.addingBankInfoLoader = false;
        console.log("addbankdata",action.payload)
        state.addBankData = action.payload;
        

      }
    );
    builder.addMatcher(
      isAnyOf(putBankDetailsAsync.fulfilled),
      (state, action) => {
        state.addingBankInfoLoader = false;
        state.putBankData = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getBankDetailsAsync.fulfilled),
      (state, action) => {
        state.addingBankInfoLoader = false;
        state.getBankDataById = action.payload;
        console.log(action.payload, "getbankdata");
      }
    );
    builder.addMatcher(
      isAnyOf(
        addBankDetailsAsync.rejected,
        putBankDetailsAsync.rejected,
        getBankDetailsAsync.rejected
      ),
      (state, action) => {
        state.addingBankInfoLoader = false;
        state.addBankInfoError = action.payload;
      }
    );
  },
  reducers: {
    emptyBankInfo: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyBankInfo } = bankDetailsSlice.actions;

export default bankDetailsSlice.reducer;
