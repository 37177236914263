
//
import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  postAssetAsync,
  getAssetAsync,
  deleteAssetByIdAsync,
  putAssetAsync,
  getAssetByIdAsync,
  getAssetTypeAsync,
} from "../async.api";

const initialState = {
  allAssets: [],
  assetLoader: false,
  postAsset: [],
  putAsset: [],
  getAssetById: [],
  deleteData: [],
  getAssetTypedropdown: [],
};

const assetSlice = createSlice({
  name: "asset",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        postAssetAsync.pending,
        getAssetAsync.pending,
        deleteAssetByIdAsync.pending,
        putAssetAsync.pending,
        getAssetByIdAsync.pending
      ),
      (state) => {
        state.assetLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(getAssetTypeAsync.pending), (state) => {
    });

    // assetDataById
    builder.addMatcher(
      isAnyOf(getAssetByIdAsync.fulfilled),
      (state, action) => {
        state.assetLoader = false;
        state.getAssetById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAssetTypeAsync.fulfilled),
      (state, action) => {
        state.assetLoader = false;
        state.getAssetTypedropdown = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(postAssetAsync.fulfilled), (state, action) => {
      state.assetLoader = false;
      state.postAsset = action.payload;
    });

    builder.addMatcher(isAnyOf(getAssetAsync.fulfilled), (state, action) => {
      state.assetLoader = false;
      state.allAssets = action.payload;
    });

    builder.addMatcher(
      isAnyOf(deleteAssetByIdAsync.fulfilled),
      (state, action) => {
        state.assetLoader = false;
        state.deleteData = action.payload;
      }
    );

    builder.addMatcher(isAnyOf(putAssetAsync.fulfilled), (state, action) => {
      state.assetLoader = false;
      state.putAsset = action.payload;
    });

    builder.addMatcher(
      isAnyOf(
        postAssetAsync.rejected,
        getAssetAsync.rejected,
        deleteAssetByIdAsync.rejected,
        putAssetAsync.rejected,
        getAssetByIdAsync.rejected
      ),
      (state) => {
        state.assetLoader = true;
      }
    );

    builder.addMatcher(isAnyOf(getAssetTypeAsync.rejected), (state) => {
      state.assetLoader = false;
    });
  },
  reducers: {
    emptyAsset: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyAsset } = assetSlice.actions;
export default assetSlice.reducer;
