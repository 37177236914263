import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getAssignAssetAsync,addAssignAssetAsync,getAllStaffAsync,updateAssignAssetAsync , getAssignAssetByIdAsync} from "../async.api";

const initialState = {
  assignAssetLoader: false,
  allAssignAssetList: [],
  assignAssetError: [],
  addAssignAsset :[],
  getAllExistingStaff:[],
  getAssignAssetById:[],
  returnDate:[]
};

export const assignAssetSlice = createSlice({
  name: "assignasset",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAssignAssetAsync.pending,addAssignAssetAsync.pending,getAllStaffAsync.pending , getAssignAssetByIdAsync.pending), (state) => {
      state.assignAssetLoader = true;
    });
    builder.addMatcher(
      isAnyOf(getAssignAssetAsync.fulfilled),
      (state, action) => {
        state.assignAssetLoader = false;
        state.allAssignAssetList = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(addAssignAssetAsync.fulfilled),
      (state, action) => {
        state.assignAssetLoader = false;
        state.addAssignAsset = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(updateAssignAssetAsync.fulfilled),
      (state, action) => {
        state.assignAssetLoader = false;
        state.returnDate = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAllStaffAsync.fulfilled),
      (state, action) => {
        state.assignAssetLoader = false;
        state.getAllExistingStaff = action.payload;
      }
    );
    builder.addMatcher(
      isAnyOf(getAssignAssetByIdAsync.fulfilled),
      (state, action) => {
        state.assignAssetLoader = false;
        state.getAssignAssetById = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(getAssignAssetAsync.rejected,addAssignAssetAsync.rejected,getAllStaffAsync.rejected , getAssignAssetByIdAsync.rejected),
      (state, action) => {
        state.assignAssetLoader = false;
        state.assignAssetError = action.payload;
      }
    );
  },
  reducers: {
    emptyassignAsset: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

export const { emptyassignAsset } = assignAssetSlice.actions;

export default assignAssetSlice.reducer;
