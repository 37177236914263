import { Box, Container } from "@mui/material";
import React from "react";
import Checkbox from '@mui/material/Checkbox';
import DataTable from "react-data-table-component";
const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

const customStyles = {
  rows: {
    style: {
      whiteSpace: "normal",
      minHeight: "50px",
      fontSize: "14px",
      fontFamily: "public sans",
      border: "1px solid hsla(0,0%,71%,.241)!important",
    },
  },
  headCells: {
    style: {
      background: "#F2F5F7",
      fontFamily: "Public Sans",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "24px",
      Color: "#212b36",
    },
  },
  cells: {
    style: {
      background: "white",
      fontFamily: "Public Sans",

      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "24px",
      Color: "#212b36",
    },
  },
};

const Attendance = ({
  columns,
  data,
  fixedHeader=true,
  paginations=true,
  toggledClearRows,
  totalcount,
  onChangeRowsPerPage,
  onChangePage,
  loader,
  selectableRowsEnabled = false,
  TitleEnabled=false,
  handleRowSelected,
  contextAction,
  payrollStyle,
  payrollPage,
 
}) => {
  return (
    <>
      <Container sx={{ mt: 4 }} maxWidth='auto'>
        <DataTable
          style={{
            border: "1px solid hsla(0,0%,71%,.241) !important",
            borderRadius: "1px !important",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          className="rounded-0"
          title={TitleEnabled}
          responsive
          pagination={paginations}
          paginationPerPage={payrollPage}
          paginationServer
          // expandableRows={expandableRows}
          // expandableRowsComponent={expandableRowsComponent}
          columns={columns}
          data={data}
          customStyles={customStyles}
          progressPending={loader}
          // progressComponent={<CustomComponentLoader padding="20px 0" size={40} />}
          persistTableHead={true}
          fixedHeader={fixedHeader}
          fixedHeaderScrollHeight={payrollStyle}
          paginationTotalRows={totalcount}    
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangePage={onChangePage}
          direction="auto"
          selectableRows={selectableRowsEnabled}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggledClearRows}
          contextActions={contextAction}
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={selectProps}
          subHeaderAlign="right"
          // sortIcon={<AiOutlineSortAscending />}
        />
      </Container>
    </>
  );
};

export default Attendance;
